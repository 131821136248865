import { render, staticRenderFns } from "./KnTableSubjects.vue?vue&type=template&id=8cd97220&scoped=true"
import script from "../KnTableSubjects/KnTableSubjects.js?vue&type=script&lang=js&external"
export * from "../KnTableSubjects/KnTableSubjects.js?vue&type=script&lang=js&external"
import style0 from "./KnTableSubjects.vue?vue&type=style&index=0&id=8cd97220&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8cd97220",
  null
  
)

export default component.exports